/* .m-e {
   margin-left: 0.6rem;
}
.m-e2 {
   margin-left: 2.1rem;
} */

.bg-red {
   background-color: red;
}

.grantTitle {
   background-color: var(--yellow-light);
   color: black;
}

.subtitle {
   background-color: var(--secondary-color);
   color: white;
}

.bgTable {
   background-color: var(--table-bg-odd);
}

.contractTitle {
   background-color: var(--yellow);
   color: white;
}

.bgContractTable {
   background-color: var(--table-bg-even);
}

.expenseTitle {
   background-color: #8745af;
   color: white;
}

.hide-on-website {
   display: none;
}

@media screen and (min-width: 768px) {
   .hide-on-website {
      display: block;
      font-size: 1.11rem;
   }
}

@media only print {
   .hide-on-website {
      display: block;
      font-size: 0.54rem;
   }
}
