.primarybar {
   color: var(--global-font);
   background-color: var(--secondary-color);
   border-radius: 1px;
   display: flex;
   position: relative;
   flex-direction: row;
   height: 4.8rem;
   justify-content: space-between;
   align-items: center;
   z-index: 11;
}

.secondbar {
   background-color: var(--secondary-color);
   z-index: -1;
   height: 3.6rem;
}

.theadwrapper {
   /* height: max-content; */
   /* display: flex; */
}

.budgetName {
   margin-right: -3rem;
   width: auto;
   border: none;
   color: inherit;
   background-color: inherit;
}

.contractamount {
   margin: 1.8rem;
}

.tableWrapper {
   width: 78vw;
}

div.dataTable {
   margin: 0px;
   padding: 0px;
   border-radius: 0 0 0.5em 0.5em;
   overflow: hidden;
   font-weight: 500;
   font-size: 0.9rem;
   border: none;
   width: 93.6%;
}

.input {
   border: 0.1rem solid rgba(140, 140, 140, 0.3);
   outline: none;
   width: 90%;
   height: fit-content;
   padding: 0 0.72rem;
   border-radius: var(--border-radius);
   color: var(--table-bg);
   font-weight: 500;
   font-size: 0.9rem;
}

.input-dollar {
   padding: 0 1.2rem;
}

div .rowItem:nth-child(2n) {
   background-color: var(--table-bg-even);
}

div .rowItem:nth-child(2n + 3) {
   background-color: var(--table-bg-odd);
}

.button {
   border-radius: 0 1rem 1rem 0;
   background-color: var(--global-font);
   padding: 0.15rem 0.6rem;
   border: none;
   outline: none;
   color: var(--secondary-color) !important;
   width: min-content;
   text-decoration: none;
}

.attribute-update {
   cursor: default;
   pointer-events: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.attribute-header {
   cursor: pointer;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

li {
   list-style: none;
}

* {
   box-sizing: border-box;
}

.list-container::-webkit-scrollbar {
   width: 10px;
   height: 10px;
   border: none;
}

::-webkit-scrollbar-thumb {
   background: var(--table-bg);
   border: none;
}

::-webkit-scrollbar-track {
   background: var(--table-bg-odd);
   border: none;
}

option {
   text-align: center;
}

.lastRowItem {
   background-color: var(--table-bg) !important;
   position: absolute;
   height: fit-content;
}

.addBtn {
   background-color: var(--global-font) !important;
   color: var(--secondary-color) !important;
   height: 3.3rem;
   width: 3.3rem;
}

.icon {
   height: 1rem;
}

.update-button {
   background-color: var(--global-font) !important;
   border-radius: 9999px !important;
   max-height: 1.8rem;
   max-width: 1.8rem;
   /* width: 1.8rem; */
}

.update-button-green {
   background-color: var(--green-light) !important;
   border-radius: 9999px !important;
   max-height: 1.8rem;
   max-width: 1.8rem;
}

.update-button--save {
   background-color: var(--yellow) !important;
   border-radius: 9999px !important;
}

.icon-update {
   height: 1.2rem;
   width: 1.2rem;
}

@media screen and (max-width: 768px) {
   .tableWrapper {
      margin-top: -0.9rem;
      width: 100vw;
   }

   div.dataTable {
      overflow: initial;
      background-color: var(--table-bg-even);
   }

   .list-container {
      overflow-y: initial;
      height: 100%;
      min-height: 72vh;
   }

   .primarybar {
      border-radius: 0;
      min-height: 6rem;
      flex-direction: row;
      height: min-content !important;
   }

   .secondbar {
      background-color: var(--secondary-color);
      z-index: -1;
      height: 14.1rem;
      font-size: 0.9rem !important;
   }

   .contractamount {
      display: flex;
      flex-direction: column;
   }

   .budgetName {
      margin: auto;
      position: sticky;
      top: 0;
      height: 3rem;
   }

   .button {
      border-radius: 0 1rem 1rem 0;
      background-color: inherit;
      color: var(--global-font) !important;
      border: none;
      padding: 0;
   }

   .btn-delete {
      height: 2.1rem;
      width: 2.1rem;
   }

   .btn-delete img {
      height: 1rem !important;
      width: 1rem !important;
   }

   .available {
      color: var(--secondary-color);
      height: 1.5rem;
      font-size: 1.2rem;
      width: min-content;
   }

   .btn-expenses {
      margin: 0 !important;
      padding: 0 !important;
   }

   div.dataTable {
      width: 100%;
      border-radius: 0;
   }

   .list-container > div:first-child {
      display: none;
   }

   .row-container {
      padding: 0.6rem;
   }

   .input {
      padding: 0 0.72rem;
      background-color: var(--global-font);
      border: 0.1rem solid rgba(140, 140, 140, 0.3);
      outline: none;
      width: 90%;
      height: min-content;
      border-radius: 10px;
      color: var(--table-bg);
      font-weight: 400;
   }

   .input-dollar--sign {
      display: none;
   }

   .attribute::before {
      content: attr(data-name);
   }

   .attribute {
      display: grid;
      grid-template-columns: 10.2rem 1fr;
      padding: 0.15rem 0;
   }

   .attribute:hover {
      color: var(--table-bg);
   }

   .dataTable-container {
      display: grid;
      grid-template-columns: 1fr;
   }

   .lastRowItem .percent {
      display: none;
   }

   .lastRowItem {
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: var(--global-font);
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      height: 4.2rem;
   }

   .lastRowItem .attribute:hover {
      color: var(--global-font);
   }

   .lastRowItem .attribute::before {
      display: none;
   }

   .separator {
      height: 4.2rem;
      background-color: var(--table-bg-odd);
   }

   footer {
      display: none;
   }

   .icon {
      display: none;
   }

   .button-mobile {
      color: var(--bs-body-color) !important;
   }
}

@media screen and (min-width: 769px) {
   .row-container {
      display: grid;
      grid-template-columns: 9.9rem 6rem 10.2rem repeat(4, 1fr) 6rem 4.5rem;
   }

   .contractamount {
      margin: 4.2rem;
      height: 4.8rem;
   }

   .separator {
      height: 6rem !important;
      background-color: var(--table-bg-odd);
   }

   .attribute {
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.6rem 0.3rem 0.6rem 0.3rem;
   }

   .attribute-object {
      padding: 0 0.9rem !important;
   }

   .input {
      padding-right: 0.6rem;
   }

   .input-dollar--sign {
      position: relative;
      padding-left: 0.6rem;
      margin-right: -1.18rem;
      color: var(--table-bg);
      height: 100%;
      z-index: 1 !important;
      font-size: 0.9rem;
   }

   .list-container {
      overflow: auto;
      position: relative;
      height: 59.6vh !important;
      max-height: 59.6vh !important;
      background-color: var(--table-bg-odd);
   }

   .list-container > .row-container:first-child {
      background-color: var(--table-bg);
      color: var(--global-font);
      position: sticky;
      top: 0;
      width: inherit;
      z-index: 10;
   }

   .lastRowItem {
      grid-template-columns: repeat(5, 1fr);
      background-color: var(--table-bg);
      color: var(--global-font);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 6rem;
   }

   .dataTable-container > .row-container:first-child .attribute {
      display: flex;
      align-items: center;
      text-overflow: initial;
      overflow: auto;
      white-space: normal;
   }
}

@media screen and (min-width: 1873px) {
   .mar {
      margin: 0 1.5rem;
   }
}

@media screen and (max-width: 820px) {
   .tableWrapper {
      margin-top: -0.9rem;
      width: 100vw;
   }

   div.dataTable {
      width: 100vw;
   }

   .primarybar {
      border-radius: 0 !important;
   }
}

.available {
   background-color: var(--green);
}

.balance {
   bottom: 0 !important;
   padding-left: 0.3rem;
}

.green-balance {
   background-color: var(--green);
}

.yellow-balance {
   background-color: var(--yellow-light);
}

.red-balance {
   background-color: var(--red);
}

.spinner {
   color: var(--global-font) !important;
}

.report-info {
   color: var(--secondary-color) !important;
}

.contract--n {
   /* font-family: "7Segments", sans-serif; */
   font-size: 1.5rem;
   background-color: var(--table-bg);
   font-weight: 500;
}

.width {
   /* width: min-content !important; */
   background-color: unset;
}

.mar {
   margin-top: 0.3rem;
}
