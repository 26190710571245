form h3 {
   color: var(--global-font) !important;
}

.login-title {
   padding-bottom: 1.2rem;
   justify-content: center;
   color: var(--table-bg);
}

.login-form {
   background-color: rgba(255, 255, 255, 0.66);
   border: none;
   outline: none;
   width: 100%;
   color: var(--table-bg) !important;
}

.login-form::placeholder {
   color: var(--table-bg) !important;
}

.login-form:focus {
   color: var(--table-bg) !important;
}

.login-button {
   background-color: var(--table-bg);
   border: none;
   outline: none;
   width: 100%;
   color: var(--global-font) !important;
}
