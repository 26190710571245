.contractamount--encumbrance {
   margin: 0;
}

.separator--contract {
   height: 3.6rem;
}

.contr {
   color: var(--green);
}

.expen {
   color: var(--yellow-light);
}

.rema {
   color: var(--red-light);
}

@media screen and (max-width: 992px) {
   .available--contract {
      font-size: 0.5rem !important;
   }
}

@media screen and (min-width: 769px) {
   .list-container--contract {
      height: calc(59.6vh - 3.6rem) !important;
   }

   .row-container--contract {
      display: grid;
      grid-template-columns: 5.4rem repeat(7, 1fr) 5.1rem 5.1rem;
   }

   .separator--contract {
      height: 3.6rem;
   }

   .contractamount--contract {
      height: auto;
   }
}
