.primarybar--encumbrance {
   background-color: var(--yellow) !important;
}

.secondbar--encumbrance {
   background-color: var(--secondary-color);
}

.secondbar > p {
   width: 90%;
}

@media screen and (max-width: 768px) {
   .lastRowItem--encumbrances {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
}

@media screen and (min-width: 769px) {
   .row-container {
      --grid-template-encumbrance: 1fr 4.5rem 7.8rem 0.7fr repeat(2, 7.8rem)
         repeat(2, 0.7fr) 4.5rem;
   }

   .list-container--contract {
      height: calc(59.6vh - 3.6rem) !important;
   }

   .row-container--encumbrance {
      display: grid;
      grid-template-columns: var(--grid-template-encumbrance);
   }

   .separator {
      height: 3.6rem;
   }

   .lastRowItem--encumbrances {
      grid-template-columns: var(--grid-template-encumbrance);
      background-color: var(--table-bg);
      color: var(--global-font);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 3.6rem;
   }
}
