.btn_account {
   text-decoration: none;
   border-radius: var( --border-radius) 0 0 var( --border-radius);
   padding: 1.2rem 3rem;
   padding-right: 5.4rem !important;
   /* width: 100%; */
   right: 0;
   background-color: var(--table-bg);
   width: fit-content;
   border: 0;
   color: var(--global-font);
   padding: 1.2rem 3rem;
   /* padding-left: 5.4rem !important; */
   align-items: center;
   justify-content: center;
   font-size: 1.5rem;
   margin-top: -2.1rem;
}

.btn_account-circle {
   align-items: center;
   background-color: var(--global-font);
   border-radius: 100%;
   display: flex;
   height: 3rem;
   justify-content: center;
   margin-left: 0.6rem;
   width: 3rem;
}

.goback__image {
   width: 2.1rem;
   height: 2.1rem;
   vertical-align: middle;
}
