.chart {
   width: 50vw !important;
}

.table {
   color: var(--secondary-color) !important;
   word-wrap: break-word;
}

.barChartWidth {
   width: 75%;
}

@media screen and (max-width: 576px) {
   .barChartWidth {
      width: 100%;
   }
   .display-it {
      display: none;
   }
}

@media screen and (min-width: 992px) {
   .display-it {
      display: block !important;
   }
}

@media only print {
   .barChartWidth {
      max-width: 100%;
   }

   .display-it {
      display: block !important;
      font-size: 2.4vw;
   }
}

@media only print and (max-width: 576px) {
}
