.tableWrapper--folders {
   width: 33vw;
}

div.dataTable--folders {
   width: 87%;
}

.list-container--folders {
   /* max-height: 48vh;
   overflow: scroll; */
   max-height: 59.6vh;
}

@media screen and (max-width: 768px) {
   .tableWrapper--folders {
      width: 100%;
   }

   div.dataTable--folders {
      width: 100%;
   }

   /* .attribute--folder {
      grid-template-columns: 1fr;
   } */

   .rowItem .attribute--folder:last-child {
      display: none;
   }
}

@media screen and (min-width: 769px) {
   .row-container--folders {
      display: grid;
      grid-template-columns: 1fr 33% 4.5rem;
   }
}
