.tableWrapper--account {
   width: 69vw;
}

div.dataTable--account {
   width: 90%;
}

.secondbar--account {
   color: var(--global-font);
   background: var(--table-bg);
   display: flex;
   position: relative;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   font-size: small !important;
   height: 3.6rem;
}

.menu--account {
   background: var(--light-bg);
}

.menu--account button {
   background-color: inherit;
   color: var(--table-bg-even) !important;
   font-size: small;
   border: none;
}

.list-container > .row-container--account:first-child {
   background-color: var(--green);
}

.addBtn--account {
   height: 2.4rem;
   width: 2.4rem;
}

.menu-nav--account {
   color: var(--table-bg) !important;
   font-size: xx-small;
}

.icon--account {
   height: 2.4rem;
   width: 2.64rem;
}

.attribute--account {
   white-space: normal;
   overflow-wrap: break-word;
}

@media screen and (max-width: 992px) {
   .tableWrapper--account {
      width: 100vw;
   }

   div.dataTable--account {
      width: 100%;
   }

   .icon--account {
      height: 2.1rem;
      width: 2.1rem;
   }

   .menu-titles {
      display: none;
   }

   .attribute--account {
      display: grid;
      grid-template-columns: 4.8rem 1fr;
      padding: 0.15rem 0;
   }

   .folder-select {
      width: 3rem !important;
   }
}

@media screen and (min-width: 769px) {
   .row-container--account {
      display: grid;
      grid-template-columns: 9rem 1fr 4.8rem 11.1rem 4.2rem;
   }
}
