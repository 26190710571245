.logo {
   height: 8.4rem;
}

nav {
   background-color: var(--global-bg);
}

.progress {
   height: 4em;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   border: 1px solid cornflowerblue;
}

.budget-battery {
   cursor: default;
   pointer-events: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   animation-duration: 0 !important;
}

@media screen and (max-width: 768px) {
   .logo {
      height: 4.5rem;
   }

   .budget-battery {
      height: 4.5rem;
      width: 9rem;
   }
}
