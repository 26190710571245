@media screen and (max-width: 768px) {
   .primarybar--projects {
      justify-content: none;
      flex-direction: column;
      height: auto;
      align-items: initial;
   }

   .contractamount--projects {
      margin: 0;
   }

   .available--projects {
      width: min-content;
   }

   .addBtn--projects {
      position: absolute;
      /* top: 0.78rem; */
      right: 1.5rem;
   }
}
