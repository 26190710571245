.current-user {
   flex-direction: row;
   width: 33vw;
   height: 2.4rem;
   position: absolute;
   top: 0;
   background-color: var(--table-bg);
   color: var(--global-font);
   border-radius: 0 0 var( --border-radius) var( --border-radius);
}

.logout-btn {
   color: var(--global-font);
   background-color: var(--red-light);
   border: none;
   height: 100%;
   padding: 0 2.1rem;
}

@media screen and (max-width: 992px) {
   .current-user {
      width: 81%;
      /* border-radius: 0; */
   }

   .role {
      display: none;
   }

   .logout-btn {
      padding: 0 1.2rem;
   }
}

@media screen and (max-width: 768px) {
   .current-user {
      width: 100%;
   }
}
