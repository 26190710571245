.login-container {
   width: 42vw;
}

.primarybar {
   color: var(--global-font);
   background-color: var(--secondary-color);
   border-radius: var(--border-radius);
   display: flex;
   flex-direction: row;
   overflow: visible;
   height: 4.8rem;
}

.login-subcontainer {
   background-color: var(--table-bg-even);
   width: 90%;
   border-radius: 0 0 var(--border-radius) var(--border-radius);
   overflow: hidden;
}

.login-col {
   background-color: var(--table-bg-even) !important;
}

.login-col > div {
   margin: 3rem 4.8rem 3rem 0;
   /* background-color: red; */
}

.BTS-logo {
   height: 7.2rem;
   opacity: 0.84;
}

@media screen and (max-width: 1900px) {
   /* .login-container {
      width: 72vw;
   } */

   .login-col > div {
      margin: 1.5rem 1.2rem;
   }
}

@media screen and (max-width: 1400px) {
   .login-col > div {
      margin: 1.5rem 1.2rem;
   }
}

@media screen and (max-width: 992px) {
   .login-container {
      width: 90vw;
   }
}

@media screen and (max-width: 768px) {
   .login-container {
      width: 100vw;
   }

   .login-subcontainer {
      width: 100%;
      border-radius: initial;
   }

   .primarybar {
      border-radius: 0;
      min-height: 6rem;
      flex-direction: row;
   }

   .login-col > div {
      margin: 0 4.8rem 6rem;
   }

   .BTS-logo {
      height: 7.2rem;
      opacity: 0.7;
   }
}

@media screen and (max-width: 576px) {
   .login-col > div {
      margin: 0 1rem 6rem;
   }

   .BTS-logo {
      height: 5.4rem;
   }
}

/* .input_login {
   border-radius: 1.4em !important;
   padding: 0.9em 1em !important;
   font-weight: 700 !important;
   background-color: #9ec8ff !important;
}

.btn_login {
   background-color: #3b5e8c !important;
   color: white !important;
   font-size: 1.2em !important;
   font-weight: 700 !important;
   padding: 0.5em 2.5em !important;
   border: 1px solid #3b5e8c !important;
   border-radius: 1em !important;
   width: 100% !important;
}
.btn_login:hover {
   background-color: #9ec8ff !important;
   border: 1px solid #3b5e8c !important;

   color: #142740 !important;
   font-size: 1.2em !important;
   font-weight: 700 !important;
   padding: 0.5em 2.5em !important;
   border-radius: 1em !important;
   width: 100% !important;
} */
