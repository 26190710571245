.goback__btn {
   text-decoration: none;
   background-color: var(--table-bg);
   border-radius: 0 var( --border-radius) var( --border-radius) 0;
   border: 0;
   color: var(--global-font);
   padding: 1.2rem 6rem;
   padding-left: 5.4rem !important;
   align-items: center;
   justify-content: center;
   font-size: 1.5rem;
   margin-top: -2.1rem;
}

.goback__btn-circle {
   align-items: center;
   background-color: var(--global-font);
   border-radius: 100%;
   display: flex;
   height: 3rem;
   justify-content: center;
   margin-right: 0.6rem;
   width: 3rem;
}

.goback__image {
   width: 2.1rem;
   height: 2.1rem;
   vertical-align: middle;
}
