@import url("https://fonts.cdnfonts.com/css/7segments");

html {
   --global-font: #ebf3ff;
   --global-font-o: rgba(235, 243, 255, 0.39);
   --secondary-color: #003151;
   --light-bg: #9ec8ff;
   --table-bg: #0f1f3b;
   --table-bg-even: #e3e3e3;
   --table-bg-odd: #cccccc;
   --green: #05c582;
   --green-light: rgba(96, 230, 161, 0.54);
   --yellow: #461ac4;
   --yellow-light: #fbe801;
   --red: #cf0e04;
   --red-light: rgba(255, 122, 107, 0.72);
   --border-radius: 0.4em;
}

body {
   color: var(--global-font);
   margin: 0;
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   position: relative;
}

a {
   display: block;
   text-decoration: none !important;
   color: var(--global-font) !important;
}

select {
   width: 100%;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   padding: 10px;
   margin: 20px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   display: none;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.bg-blue {
   background-color: #142740;
}

.bg-blue-two {
   background-color: #3b5e8c7c;
   border-radius: 0 0 1em 1em;
   padding: 3em;
}

.budget {
   border-radius: var(--border-radius) !important;
   max-width: 5.4rem;
}

.green-budget {
   background-color: var(--green);
   border: 1px solid var(--table-bg);
   border-radius: var(--border-radius) !important;
}

.yellow-budget {
   background-color: var(--yellow-light);
   border: 1px solid var(--table-bg);
   color: var(--secondary-color) !important;
   border-radius: var(--border-radius) !important;
}

.red-budget {
   background-color: var(--red);
   border: 1px solid var(--table-bg);
   border-radius: var(--border-radius) !important;
}

.btn-group-2 {
   border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
}
.btn-group-1 {
   border-radius: 99999px 0 0 99999px !important;
}
.btn {
   font-weight: 500 !important;
}

/* .modal-content {
   border-radius: 2.5em !important;
   overflow: hidden;
}
.modal-header {
   border-radius: 2em 2em 0 0 !important;
   color: var(--global-font);
   background-color: var(--secondary-color);
   border-bottom: 0 !important;
}
.modal-body {
   background-color: var(--table-bg-even);
}

.modal-footer {
   background-color: var(--table-bg-even) !important;
   border-top: 0 !important;
   justify-content: center !important;
}
.btn {
   border-radius: 2em !important;
}
 */

 .pointer:hover {
    cursor: pointer;
 }
